
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import configureStoreProd from "./config/configureStore.prod";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import { lazy, Suspense } from 'react';
import { memo } from 'react';


import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import "./App.css";
import "./scss/main.scss";


const { persistor, store } = configureStoreProd();

const Loading=({id='',className=''})=>{
  return <>
  <div id={id} className={`loaderDiv ${className}`}>
          <div>
            <img
              src="/assets/img/loader.gif"
              alt="logo"
              className="loaderlogo"
            />
          </div>
        </div>
  </>
}


const App=memo(function App() {
  const routes = [
    { url: "/login", path:'Login' },
    { url: "/stafflogin", path:'StaffLogin' },
    // {url:'/signup',element:<Signup />},
    { url: "/dashboard", path:'Dashboard'},
    { url: "*", path:'NotFoundPage' },
    { url: "/profile",path:'Profile' },
    { url: "/profile/:tab", path:'Settings' },
    { url: "/forgotpassword",path:'Forgotpassword'},
    { url: "/resetpassword",path:'Resetpassword'},
    { url: "/roles", path:'Roles' },
    { url: "/roles/add", path:'Roles/AddEdit' },
    { url: "/roles/edit/:id",path:'Roles/AddEdit' },
    { url: "/user",path:'Users'},
    { url: "/user/edit/:id", path:'Users/AddEdit'},
    { url: "/user/add", path:'Users/AddEdit'},
    { url: "/user/detail/:id",path:'Users/View' },
    { url: "/", element: <Navigate to="/login" /> },
    { url: "/category", path:'Categories' },
    { url: "/category/add",path:'Categories/AddEdit' },
    { url: "/category/detail/:id",path:'Categories/View'},
    { url: "/category/edit/:id",path:'Categories/AddEdit' },
    { url: "/product", path:'Products'},
    { url: "/product/add",path:'Products/AddEdit'},
    { url: "/product/detail/:id",path:'Products/View' },
    { url: "/product/edit/:id", path:'Products/AddEdit' },
    { url: "/faq", path:'FAQ' },
    { url: "/faq/add",path:'FAQ/AddEdit' },
    { url: "/faq/detail/:id",path:'FAQ/View' },
    { url: "/faq/edit/:id", path:'FAQ/AddEdit'},
    { url: "/content", path:'Content' },
    { url: "/content/detail/:slug",path:'Content/View' },
    { url: "/content/add",path:'Content/AddEdit' },
    { url: "/content/edit/:slug",path:'Content/AddEdit' },
    { url: "/newsletter", path:'Newsletter' },
    { url: "/newsletter/detail/:id",path:'Newsletter/View' },
    { url: "/question", path:'Questions'},
    { url: "/question/add",path:'Questions/AddEdit' },
    { url: "/question/detail/:id", path:'Questions/View' },
    { url: "/question/edit/:id", path:'Questions/AddEdit' },
    { url: "/subscription", path:'Subscriptions' },
    { url: "/subscription/detail/:id",path:'Subscriptions/View' },
    { url: "/tag", path:'Tags' },
    { url: "/tag/add", path:'Tags/AddEdit'},
    { url: "/tag/detail/:id",path:'Tags/View' },
    { url: "/tag/edit/:id", path:'Tags/AddEdit' },

    { url: "/strain", path:'Strain' },
    { url: "/strain/add", path:'Strain/AddEdit'},
    { url: "/strain/detail/:id",path:'Strain/View' },
    { url: "/strain/edit/:id", path:'Strain/AddEdit' },

    { url: "/flavor", path:'Flavor' },
    { url: "/flavor/add", path:'Flavor/AddEdit'},
    { url: "/flavor/detail/:id",path:'Flavor/View' },
    { url: "/flavor/edit/:id", path:'Flavor/AddEdit' },


    { url: "/banner", path:'Banners' },
    { url: "/banner/add", path:'Banners/AddEdit'},
    { url: "/banner/detail/:id",path:'Banners/View' },
    { url: "/banner/edit/:id", path:'Banners/AddEdit' },
    { url: "/plans", path:'Plans' },
    { url: "/plans/add", path:'Plans/AddEdit'},
    { url: "/plans/detail/:id",path:'Plans/View' },
    { url: "/plans/edit/:id", path:'Plans/AddEdit' },
    { url: "/blog", path:'Blogs' },
    { url: "/blog/add",path:'Blogs/AddEdit' },
    { url: "/blog/detail/:id",path:'Blogs/View' },
    { url: "/blog/edit/:id",path:'Blogs/AddEdit'},
    { url: "/resources", path:'Resources' },
    { url: "/resources/add",path:'Resources/AddEdit' },
    { url: "/resources/detail/:id",path:'Resources/View' },
    { url: "/resources/edit/:id",path:'Resources/AddEdit'},
    { url: "/subscribers",path:'Emails' },
    { url: "/subscribers/send-newsletter",path:'Emails/Send' },
    { url: "/customers/:type", path:'GrooveGroup' },
    { url: "/customers/:type/:membership", path:'GrooveGroup' },
    { url: "/customers", path:'GrooveGroup' },
    { url: "/customers/edit/:id",path:'GrooveGroup/AddEdit' },
    { url: "/customers/add", path:'GrooveGroup/AddEdit' },
    { url: "/customers/detail/:id",path:'GrooveGroup/View' },
    { url: "/videos/:type",path:'Videos' },
    { url: "/videos/:type/add", path:'Videos/AddEdit' },
    { url: "/videos/edit/:id", path:'Videos/AddEdit' },
    { url: "/videos/detail/:id",path:'Videos/View' },
    { url: "/audio/:type",path:'Audio'},
    { url: "/audio/:type/add",path:'Audio/AddEdit' },
    { url: "/audio/edit/:id", path:'Audio/AddEdit' },
    { url: "/audio/detail/:id",path:'Audio/View' },
    { url: "/notifications",path:'Messages'},
    { url: "/notifications/detail/:id", path:'Messages/View'},
    { url: "/notifications/send-notification",path:'Messages/Send'},
    { url: "/chat", path:'Chats'},
    { url: "/orders", path:'Orders' },
    { url: "/rewardpoints",path:'rewardpoints' },
    { url: "/rewardpoints/add",path:'rewardpoints/AddEdit'},
    { url: "/rewardpoints/edit/:id",path:'rewardpoints/AddEdit' },
    { url: "/rewardpoints/detail/:id",path:'rewardpoints/View'},
    { url: "/importproducts", path:'Importproducts'},
    { url: "/importproducts/edit/:importId",path:'Products/AddEdit' },
    { url: "/importproducts/detail/:id",path:'Importproducts/View' },
    { url: "/referrals",path:'Referrals' },
    { url: "/referrals/detail/:id",path:'Referrals/View'},
    { url: "/discount",path:'Discounts' },
    { url: "/discount/detail/:id",path:'Discounts/View'},
    { url: "/reward-type", path:'RewardTypes' },
    { url: "/reward-type/add", path:'RewardTypes/AddEdit'},
    { url: "/reward-type/detail/:id",path:'RewardTypes/View' },
    { url: "/reward-type/edit/:id", path:'RewardTypes/AddEdit' },
  ];

  sessionStorage.clear();

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={"loading ..."} persistor={persistor}>
          <Router>
          <Suspense fallback={<Loading />}>
              <Routes>
                {routes.map((itm) => {
                  const Component = lazy(() => import(`./Pages/${itm.path}`));
                  return <Route path={itm.url} element={itm.element||null} Component={itm.element?null:Component} />;
                })}
              </Routes>
              </Suspense>
          </Router>
        </PersistGate>
      </Provider>
      <Loading id="loader" className="d-none" />
      <ToastContainer position="top-right" />
    </>
  );
})

export default App;
